import $ from 'jquery';
import setDefaultRowHeights from './defaultRowHeights';
import scaleFrontHeader from './scaleFrontHeader';
import handleFixedNavbar from './handleFixedNavbar';
import mediaQueriesVars from './mediaQueriesVariables';
var mediaQueries = mediaQueriesVars.mediaQueries;
var activeMediaQuery = mediaQueriesVars.activeMediaQuery;

module.exports = function responsiveHandleResize() {
   /**
   * Do things that should be done after resize
   */

    //recalculate row heights
    setDefaultRowHeights();

    if (typeof (Drupal.settings.nxte_responsive) != 'undefined' && typeof (Drupal.settings.nxte_responsive.media_queries) != 'undefined') {
        mediaQueries = Drupal.settings.nxte_responsive.media_queries;
    }

    //check for crossed CSS breakpoint
    var newActiveMediaQuery = 'xs';
    if (Modernizr.mq('only all') === false || mediaQueries === false) {
        newActiveMediaQuery = 'none';
    } else {
      $(mediaQueries).each(function(i) {
          if (Modernizr.mq('(min-width: ' + mediaQueries[i].width + 'px)')) {
              newActiveMediaQuery = mediaQueries[i].id;
          }
      });

    }

    if (newActiveMediaQuery != activeMediaQuery) {
        //call function when a CSS breakpoint was crossed
        responsisveMediaQueryChanged(newActiveMediaQuery);
    }


    //Set region header's height on front page
    if (activeMediaQuery == "xs" || activeMediaQuery == "sm" || activeMediaQuery == "md") {
        $('.front .header-wrapper ')
                .css('paddingTop', '95px')
                .height((($(window).height() - $('#navbar').outerHeight() - $('.region-header').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : $('.region-header').outerHeight()))

    } else {
        $('.front .header-wrapper')
                //   .css('paddingTop', '0px')
                //   .height(($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - 50 - $('.region-header').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() :((activeMediaQuery == "xl") ? ($(window).height() - $('#navbar').outerHeight()) : $('.region-header').outerHeight()))
                //   .css('paddingTop', ($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.region-header').outerHeight() + 50) / 2);
                .css('paddingTop', '0px')
                .height(($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.region-header').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : ((activeMediaQuery == "xl") ? ($(window).height() - $('#navbar').outerHeight()) : $('.region-header').outerHeight()))
                .css('paddingTop', (40 + $(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.region-header').outerHeight()) / 2);
        //Scale region header when window height too small
        $('.region-header').removeAttr('style');

        if ((activeMediaQuery == "xl" || activeMediaQuery == "lg") && $('.front').length) {
            var headerWrapperHeight = $('.front .header-wrapper').outerHeight(),
                    regionHeaderHeight = $('.region-header').outerHeight(),
                    headerArrowHeight = $('.arrow-inner').outerHeight(),
                    headerTopBar = $('.top-content').outerHeight(),
                    ratioHeaderWrapperToRegionHeader = 0.86;

            //   if( headerWrapperHeight <= regionHeaderHeight + headerArrowHeight +50) {
            //     var regionHeaderScale = ratioHeaderWrapperToRegionHeader * (headerWrapperHeight - headerArrowHeight - 50) / regionHeaderHeight,
            //     marginHeaderTop = (1 - ratioHeaderWrapperToRegionHeader)/2 * (headerWrapperHeight - headerArrowHeight -50),
            //     regionHeaderTop = regionHeaderHeight / 2 * ( 1 - regionHeaderScale ) - marginHeaderTop - 50;
            if (headerWrapperHeight <= regionHeaderHeight + headerArrowHeight + headerTopBar) {
                var regionHeaderScale = ratioHeaderWrapperToRegionHeader * (headerWrapperHeight - headerArrowHeight) / regionHeaderHeight,
                        marginHeaderTop = (1 - ratioHeaderWrapperToRegionHeader) / 2 * (headerWrapperHeight - headerArrowHeight),
                        regionHeaderTop = regionHeaderHeight / 2 * (1 - regionHeaderScale) - marginHeaderTop;

                /*$('.front .header-wrapper').css('paddingTop', $('#navbar').outerHeight() );*/

                $('.region-header').css(
                        {
                            '-ms-transform': 'scale(' + regionHeaderScale + ')',
                            '-webkit-transform': 'scale(' + regionHeaderScale + ')',
                            'transform': 'scale(' + regionHeaderScale + ')',
                            'position': 'relative',
                            'top': '-' + regionHeaderTop + 'px'
                        }
                );
            }

        }
    }

    //Set region header's height on landing page
    if (activeMediaQuery == "xs" || activeMediaQuery == "sm") {
        $('.node-type-landing-page .header')
                .css('paddingTop', '15px')
                .height((($(window).height() - $('#navbar').outerHeight() - $('.landing-header-wrapper').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : $('.landing-header-wrapper').outerHeight()))

    } else {

        $('.node-type-landing-page .header')
                .css('paddingTop', '0px')
                .height(($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.landing-header-wrapper').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : ((activeMediaQuery == "xl") ? ($(window).height() - $('#navbar').outerHeight()) : $('.landing-header-wrapper').outerHeight()))
                .css('paddingTop', ($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.landing-header-wrapper').outerHeight()) / 2);

        //Scale region header when window height too small
        $('.landing-header-wrapper').removeAttr('style');

        if (activeMediaQuery == "xl" && $('.node-type-landing-page').length) {
            var headerWrapperHeight = $('.node-type-landing-page .header').outerHeight(),
                    regionHeaderHeight = $('.landing-header-wrapper').outerHeight(),
                    headerArrowHeight = $('.arrow-inner').outerHeight(),
                    ratioHeaderWrapperToRegionHeader = 0.92;

            if (headerWrapperHeight <= regionHeaderHeight + headerArrowHeight) {
                var regionHeaderScale = ratioHeaderWrapperToRegionHeader * (headerWrapperHeight - headerArrowHeight) / regionHeaderHeight,
                        marginHeaderTop = (1 - ratioHeaderWrapperToRegionHeader) / 2 * (headerWrapperHeight - headerArrowHeight),
                        regionHeaderTop = regionHeaderHeight / 2 * (1 - regionHeaderScale) - marginHeaderTop;

                /*$('.front .header-wrapper').css('paddingTop', $('#navbar').outerHeight() );*/

                $('.landing-header-wrapper').css(
                        {
                            '-ms-transform': 'scale(' + regionHeaderScale + ')',
                            '-webkit-transform': 'scale(' + regionHeaderScale + ')',
                            'transform': 'scale(' + regionHeaderScale + ')',
                            'position': 'relative',
                            'top': '-' + regionHeaderTop + 'px'
                        }
                );
            }

        }
    }


    //Set region header's height on landing page
    if (activeMediaQuery == "xs" || activeMediaQuery == "sm") {
        $('.node-landing-page .header')
                .css('paddingTop', '15px')
                .height((($(window).height() - $('#navbar').outerHeight() - $('.landing-header-wrapper').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : $('.landing-header-wrapper').outerHeight()))

    } else {

        $('.node-landing-page .header')
                .css('paddingTop', '0px')
                .height(($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.landing-header-wrapper').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : ((activeMediaQuery == "xl") ? ($(window).height() - $('#navbar').outerHeight()) : $('.landing-header-wrapper').outerHeight()))
                .css('paddingTop', ($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.landing-header-wrapper').outerHeight()) / 2);

        //Scale region header when window height too small
        $('.landing-header-wrapper').removeAttr('style');

        if (activeMediaQuery == "xl" && $('.node-landing-page').length) {
            var headerWrapperHeight = $('.node-landing-page .header').outerHeight(),
                    regionHeaderHeight = $('.landing-header-wrapper').outerHeight(),
                    headerArrowHeight = $('.arrow-inner').outerHeight(),
                    ratioHeaderWrapperToRegionHeader = 0.92;

            if (headerWrapperHeight <= regionHeaderHeight + headerArrowHeight) {
                var regionHeaderScale = ratioHeaderWrapperToRegionHeader * (headerWrapperHeight - headerArrowHeight) / regionHeaderHeight,
                        marginHeaderTop = (1 - ratioHeaderWrapperToRegionHeader) / 2 * (headerWrapperHeight - headerArrowHeight),
                        regionHeaderTop = regionHeaderHeight / 2 * (1 - regionHeaderScale) - marginHeaderTop;

                /*$('.front .header-wrapper').css('paddingTop', $('#navbar').outerHeight() );*/

                $('.landing-header-wrapper').css(
                        {
                            '-ms-transform': 'scale(' + regionHeaderScale + ')',
                            '-webkit-transform': 'scale(' + regionHeaderScale + ')',
                            'transform': 'scale(' + regionHeaderScale + ')',
                            'position': 'relative',
                            'top': '-' + regionHeaderTop + 'px'
                        }
                );
            }

        }
    }

    //Set width for dropdown list "Sort of kitchen" on restaurant page
    $('#edit-nxte-restaurant-kitchen-menu').width($('#edit-right').width());

    //Replace blocks in sidebar on bestellen page
    if (activeMediaQuery == "xs" || activeMediaQuery == "sm" || activeMediaQuery == "md") {
        $('.giftcard-pane-cart-sidebar .giftcard-pane-sidebar').appendTo('#giftcard-checkout-form');
    } else {
        $('#giftcard-checkout-form .giftcard-pane-sidebar').appendTo('.giftcard-pane-cart-sidebar');
    }

    //Scrollig of sidebar on bestellen page on LG+
    if ( $('body').hasClass('page-giftcards') ) {
      var bulletPoints = $('.page-giftcards .block.block-giftcard .left ul');
      var bulletPointsHeight = bulletPoints.height();
      var bulletPointsHeightWithMargin = bulletPoints.outerHeight(true);
      var bulletPointOffset = bulletPoints.offset().top;
      var sidebarOldOffset = $("#edit-cart-sidebar").offset().top;
      var paddingValue = sidebarOldOffset - (bulletPointOffset + bulletPointsHeight);
    }
    $(window).scroll(function() {

        var sidebar = $('.giftcard-pane-cart-sidebar');
        var bestellenForm = $('#giftcard-checkout-form');

        if ( !bestellenForm.length ) { return; }
        // For XL resolution
        if ( $('body').hasClass('mq-xl') ) {

            if (sidebar.length && bestellenForm.length) {

                var minScroll = bulletPointOffset - $('#navbar').outerHeight() - 10;
                var maxScroll = bestellenForm.offset().top + bestellenForm.outerHeight();

                if ( $(window).scrollTop() > minScroll ) {
                    var rightPosition = ( ($('#page').width() - $('#main-content').width())  / 2) + "px";
                    var topSideBarPosition = ($('#navbar').outerHeight() + bulletPointsHeightWithMargin) + paddingValue + 35 + "px";
                    var topBulletPointPosition = $('#navbar').outerHeight(true) + 60 + "px";
                    sidebar.css({
                        "position": "fixed",
                        "top": topSideBarPosition,
                        "right": rightPosition
                    });
                    bulletPoints.css({
                        "position": "fixed",
                        "top": topBulletPointPosition,
                        "right": rightPosition
                    });
                };
                if ( $(window).scrollTop() + bulletPointsHeightWithMargin + 60 < bestellenForm.offset().top - $('#navbar').outerHeight(true) ) {
                    sidebar.attr("style", "");
                    bulletPoints.attr("style", "");
                };
                if (sidebar.offset().top + sidebar.outerHeight(true) > maxScroll) {
                    sidebar.css({
                        "position": "absolute",
                        "top": bestellenForm.outerHeight() - sidebar.outerHeight(),
                        "right": 0
                    });
                    bulletPoints.css({
                        "position": "absolute",
                        "top": bestellenForm.outerHeight() - sidebar.outerHeight() + 60,
                        "right": 0
                    })
                };

            }
        }
        // For LG resolution
        else if ( $('body').hasClass('mq-lg') ) {

            if (sidebar.length && bestellenForm.length) {

                var minScroll = bestellenForm.offset().top - $('#navbar').outerHeight(true);
                var maxScroll = bestellenForm.offset().top + bestellenForm.outerHeight();

                if ( $(window).scrollTop() > minScroll ) {
                    var rightPosition = ( ($('#page').width() - $('#main-content').width())  / 2);
                    sidebar.css({
                        "position": "fixed",
                        "top": $('#navbar').outerHeight() + 20,
                        "right": rightPosition + "px"
                    });
                };
                if ( $(window).scrollTop() < minScroll ) {
                    sidebar.attr("style", "");
                };
                if (sidebar.offset().top + sidebar.outerHeight() > maxScroll) {
                    sidebar.css({
                        "position": "absolute",
                        "top": bestellenForm.outerHeight() - sidebar.outerHeight(),
                        "right": 0
                    })
                };

            }

        } else {
            return;
        }

    });


  function responsisveMediaQueryChanged(newActiveMediaQuery) {
    if (newActiveMediaQuery == 'none' || mediaQueries === false) return;

    /**
     * Do things that should be done after crossing a break-point
     */

    //remove all mq-# classes from body tag
    $('body').removeClass('mq-xs');

    $(mediaQueries).each(function(i) {
        $('body').removeClass('mq-' + mediaQueries[i].id);
    });

    //add current mq-# class to body tag
    $('body').addClass('mq-' + newActiveMediaQuery);

    //leave this
    activeMediaQuery = newActiveMediaQuery;
  }

}
