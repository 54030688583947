import $ from 'jquery';

// put helpdesk into separate page, not popup
module.exports = function helpdeskIFrame() {
  if (window.Polly !== undefined) {
    var pollyIframe = $('#pollyHelp iframe');
    pollyIframe.get(0).src = Polly.config.baseUrl;
    pollyIframe.show().css('height', 1000);
  }
}
