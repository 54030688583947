import $ from 'jquery';

// Sets a default .row heights on all of the site

module.exports = function nxte_default_set_row_heights() {

  $('.row').each(function() {

      var rowInnerHeight = 0;

      $(this).find('> *').each(function() {

          rowInnerHeight = Math.max($(this).outerHeight(), rowInnerHeight);

      });

      $(this).css('min-height', rowInnerHeight);

  });

}
