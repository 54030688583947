import $ from 'jquery';

//Sticky Button front Page

module.exports = function stickyButtonfrontPage() {

  if ($('body').hasClass('front')) {
    var button = $('.front .green-button');
    var buttonOffset = button.offset().top+100;

    function addSticky() {
      if ($(window).width() < 716) {
        if ($(window).scrollTop() >= buttonOffset) {
          $('body').addClass('sticky-button');
        } else {
          $('body').removeClass('sticky-button');
        }
      }
      else {
        $('body').removeClass('sticky-button');
      }
    }

    $(window).scroll(function() {
      addSticky();
    });

    $(window).resize(function() {
      addSticky();
    });
  }

}