import $ from 'jquery';

// Close pop-up's by clicking on overlay

module.exports = function drupalBehaviors_closePopUpOnBgClick() {
    Drupal.behaviors.ctools_backdrop_close = {
        attach: function(context, settings) {

          // close first type UI popup on overlay click
          $('#modalBackdrop').once('ctools_backdrop_close', function() {
              $(this).click(function() {
                  Drupal.CTools.Modal.dismiss();
              });
          });

          // close jquery UI popup on overlay click
          $(document).on('click', '.ui-widget-overlay', function(){
              $(".ui-dialog-titlebar-close").trigger('click');
          });

        }
    }
}
