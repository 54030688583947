import $ from 'jquery';

// modernizer queries

module.exports = function modernizrMediaQueries() {

    if (Modernizr.mq('only all') === false) {
        $('body').addClass('no-mq');
    }
    else {
        $('body').addClass('mq');
    }

}
