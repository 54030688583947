import $ from 'jquery';

/*-----------------------------------------------------------------*//**
* Go to top button
*
* @author          Viktor Starynskyi
* @project         CACO1607 - CB
* @added           14.10.2017
* @contributors    Viktor Starynskyi
*/

module.exports = function goToTopButton() {

    var btn = $('#goToTop');

    $(window).scroll(function(e) {
        if ( $(window).scrollTop() > 200 ) {
            btn.show();
        } else {
            btn.hide();
        }
    });

    btn.on('click', function(e){
        e.preventDefault();

        $('html,body').animate({
            scrollTop: 0
        }, 500);
    });

}
