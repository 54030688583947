import $ from 'jquery';

// Style for input type checkbox

module.exports = function drupalBehaviors_responsiveIcheck() {
    Drupal.behaviors.nxte_responsive_icheck = {
    attach: function(context, settings) {
      if ($('body').hasClass('imce'))
            return;
      $('input[type="radio"], input[type="checkbox"]').each(function() {
          if (!$(this).hasClass('icheck-progressed') && $.isFunction($(this).icheck)) {
              $(this).icheck();
              $(this).addClass('icheck-progressed');
          }
      });
    }
  };
}
