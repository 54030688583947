import $ from 'jquery';
import setDefaultRowHeights from '../defaultRowHeights';

// Sets a default .row heights

module.exports = function drupalBehaviors_responsiveRowHeights() {
  Drupal.behaviors.nxte_responsive_row_height = {
    attach: function(context, settings) {
      setDefaultRowHeights();
    }
  };
}
