import $ from 'jquery';
import mediaQueriesVars from './mediaQueriesVariables';
var activeMediaQuery = mediaQueriesVars.activeMediaQuery;
// Scale front header image

module.exports = function scaleFrontHeader() {

    //Set region header's height on front page
    if (activeMediaQuery == "xs" || activeMediaQuery == "sm" || activeMediaQuery == "md") {
        $('.front .header-wrapper ')
                .css('paddingTop', '65px')
                .height((($(window).height() - $('#navbar').outerHeight() - $('.region-header').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : $('.region-header').outerHeight()))

    } else {
        $('.front .header-wrapper')
                .css('paddingTop', '0px')
                .height(($(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.region-header').outerHeight()) > 0 ? $(window).height() - $('#navbar').outerHeight() : ((activeMediaQuery == "xl") ? ($(window).height() - $('#navbar').outerHeight()) : $('.region-header').outerHeight()))
                .css('paddingTop', (40 + $(window).height() - $('#navbar').outerHeight() - $('.arrow-inner').outerHeight() - $('.region-header').outerHeight()) / 2);
        //Scale region header when window height too small
        $('.region-header').removeAttr('style');

        if ((activeMediaQuery == "xl" || activeMediaQuery == "lg") && $('.front').length) {
            var headerWrapperHeight = $('.front .header-wrapper').outerHeight(),
                    regionHeaderHeight = $('.region-header').outerHeight(),
                    headerArrowHeight = $('.arrow-inner').outerHeight(),
                    ratioHeaderWrapperToRegionHeader = 0.86;

            if (headerWrapperHeight <= regionHeaderHeight + headerArrowHeight) {
                var regionHeaderScale = ratioHeaderWrapperToRegionHeader * (headerWrapperHeight - headerArrowHeight) / regionHeaderHeight,
                        marginHeaderTop = (1 - ratioHeaderWrapperToRegionHeader) / 2 * (headerWrapperHeight - headerArrowHeight),
                        regionHeaderTop = regionHeaderHeight / 2 * (1 - regionHeaderScale) - marginHeaderTop;

                $('.region-header').css(
                        {
                            '-ms-transform': 'scale(' + regionHeaderScale + ')',
                            '-webkit-transform': 'scale(' + regionHeaderScale + ')',
                            'transform': 'scale(' + regionHeaderScale + ')',
                            'position': 'relative',
                            'top': '-' + regionHeaderTop + 'px'
                        }
                );
            }

        }
    }
}
