import $ from 'jquery';
import setDefaultRowHeights from './modules/defaultRowHeights';
import visitorInfo from './modules/visitorInfo';
import stickPlaceholders from './modules/stickPlaceholders';
import bgForLandingPage from './modules/bgForLandingPage';
import modernizrMediaQueries from './modules/modernizrMediaQueries';
import goToTopButton from './modules/goToTopButton';
import helpdeskIFrame from './modules/helpdeskIFrame';
import toggleMobileMenu from './modules/toggleMobileMenu';
import responsiveHandleResize from './modules/responsiveHandleResize';
import initializeGoogleMap from './modules/initializeGoogleMap';
import handleDownArrowFront from './modules/handleDownArrowFront';
import hashLinksSmoothScroll from './modules/hashLinksSmoothScroll';
import cardnumberAutomaticFocus from './modules/cardnumberAutomaticFocus';
import addLinkWrapAroundRatingFrontPage from './modules/addLinkWrapAroundRatingFrontPage';
import responsiveSaldochecker from './modules/responsiveSaldochecker';
import changeBlocksPositionOnMobile from './modules/changeBlocksPositionOnMobile';
import stickyButtonfrontPage from './modules/stickyButtonfrontPage';

import drupalBehaviors_formPlaceholders from './modules/drupalBehaviors/drupalBehaviors_formPlaceholders';
import drupalBehaviors_lastChilds from './modules/drupalBehaviors/drupalBehaviors_lastChilds';
import drupalBehaviors_responsiveEmbedWraper from './modules/drupalBehaviors/drupalBehaviors_responsiveEmbedWraper';
import drupalBehaviors_responsiveRowHeights from './modules/drupalBehaviors/drupalBehaviors_responsiveRowHeights';
import drupalBehaviors_responsiveIcheck from './modules/drupalBehaviors/drupalBehaviors_responsiveIcheck';
import drupalBehaviors_closePopUpOnBgClick from './modules/drupalBehaviors/drupalBehaviors_closePopUpOnBgClick';
import drupalBehaviors_restaurantPager from './modules/drupalBehaviors/drupalBehaviors_restaurantPager';

$(document).ready(function() {

  // Add site visitor info like his operating system and browser to the body class;
  visitorInfo();

  // Set row heights after all images load;
  $('body').imagesLoaded(function($images, $proper, $broken) {
      setDefaultRowHeights();
  });

  //header BG for node-type-landingpage
  bgForLandingPage();

  // kaartnummer  automatic focus
  cardnumberAutomaticFocus();

  if ($('#googleMapContact').length != 0) {
    google.maps.event.addDomListener(window, 'load', initializeGoogleMap);
  }

  // add an arrow down on top of front page
  handleDownArrowFront();

  // Add smooth scroll when clicking on hash links
  hashLinksSmoothScroll();

  //set timeout for handling browser resize
  var handleResizeAfterResizeTimeOut;

  //Sticky Button front Page
  stickyButtonfrontPage();

  //On dinerbon-bestellen page change blocks position on mobile
  changeBlocksPositionOnMobile();

  $(window).resize(function() {
      clearTimeout(handleResizeAfterResizeTimeOut);
      handleResizeAfterResizeTimeOut = setTimeout(responsiveHandleResize, 300);
  }).trigger('resize');

  // modernizer queries
  modernizrMediaQueries();

  // Go to top button
  goToTopButton();

  //Handles click on mobile menu button
  toggleMobileMenu();

  // put helpdesk into separate page, not popup
  helpdeskIFrame();

  // adds a link wraper around rating review on front page
  addLinkWrapAroundRatingFrontPage();

})

//Style form placeholders
stickPlaceholders();

// Style form placeholders
drupalBehaviors_formPlaceholders();

// Adds a class of last child to last child element like p or blockquote
drupalBehaviors_lastChilds();

// Adds a wraper around objects like: iframe embed object, currently didn't find a place where it's used
drupalBehaviors_responsiveEmbedWraper();

// Sets a default .row heights
drupalBehaviors_responsiveRowHeights();

// Style for input type checkbox
drupalBehaviors_responsiveIcheck();

// Close pop-up's by clicking on overlay
drupalBehaviors_closePopUpOnBgClick();

// Restaurant pager
drupalBehaviors_restaurantPager();

// responsive height of Saldochecker iframe
responsiveSaldochecker();


