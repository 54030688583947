import $ from 'jquery';

// Creates and handles click on mobile menu button on small screens

module.exports = function toggleMobileMenu() {

    var curScroll = 0;

    $('#main-menu').find('.region-navigation-xs').after('<div class="menu-overlay" id="menu-overlay"></div>');

    $('.nav-toggle').click(function() {
        $(this).toggleClass('active');
        $('.region-navigation-xs').show().animate({'left': 0}, 400);
        $('#menu-overlay').fadeIn(400);
        curScroll = $(window).scrollTop();
        $('body').addClass('_isOverlayed');
        $(window).scrollTop(0);
        return false;
    });

    function hideMenu() {
       $('.region-navigation-xs').animate({'left': '-100%'}, 400);
       $('#menu-overlay').fadeOut(400);
       $('body').removeClass('_isOverlayed');
       $(window).scrollTop(curScroll);
    }

    $('#menu-overlay').click(hideMenu);

    var links = $(".region-navigation-xs").find('a');
    links.each(function(link){
        $(this).click(hideMenu);
    });

}
