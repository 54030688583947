import $ from 'jquery';

// Add smooth scroll when clicking on hash links

module.exports = function hashLinksSmoothScroll() {

  $('a[href*=#]').each(function() {
    if ($(this.hash).length > 0) {
      $(this).click(function(e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: ($(this.hash).offset().top - $('#navbar').height() - 100)
        }, 300);
      });
    }
  });

  if (window.location.hash != '') {
    var anchorElement = $(window.location.hash);
    if ($(anchorElement).length > 0) {
      window.location.hash = '';
      setTimeout(function() {
        $('body').imagesLoaded(function($images, $proper, $broken) {
            $('html,body').animate({
                scrollTop: ($(anchorElement).offset().top - $('#navbar').height() - 100)
            }, 300);
        });
      }, 500);
    }
  }

}
