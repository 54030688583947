import $ from 'jquery';

// Style form placeholders

module.exports = function drupalBehaviors_formPlaceholders() {
  Drupal.behaviors.nxte_form_placeholders = {
    attach: function(context, settings) {
      if (typeof (Drupal.settings.nxte) != 'undefined' && typeof (Drupal.settings.nxte.placeholder_elements) != 'undefined') {
        jQuery(Drupal.settings.nxte.placeholder_elements).stickyPlaceholders();
      }
    }
  };
}

