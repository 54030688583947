import $ from 'jquery';

//header BG for node-type-landingpage

module.exports = function bgForLandingPage() {

    var bgSRC = $(".node-type-landing-page .header .field-name-field-background-image img").attr('src');
    $(".node-type-landing-page .header").css('background-image', 'url(' + bgSRC + ")");

    var bgSRC2 = $(".node-landing-page .header .field-name-field-background-image img").attr('src');
    $(".node-landing-page .header").css('background-image', 'url(' + bgSRC2 + ")");

    $('.region-navigation-xs .leaf a').click(function() {
        $('.region-navigation-xs').slideToggle(250);
    });

}
