import $ from 'jquery';

// add an arrow down on top of front page

module.exports = function handleDownArrowFront() {

  $('<div id="scroll-arrow"><div class="arrow-inner"></div></div>').click(function() {
      $("html, body").animate({scrollTop: ($('.region-highlited, .node-type-landing-page .field-name-search-block-field, .node-landing-page-full .group-search').offset().top - $('#navbar').height())}, 300);
  }).insertBefore('.region-highlited, .node-type-landing-page .group-search, .node-landing-page-full .group-search');

}
