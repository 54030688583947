import $ from 'jquery';

// Adds a wraper around objects like: iframe embed object, currently didn't find a place where it's used

module.exports = function drupalBehaviors_responsiveEmbedWraper() {
  Drupal.behaviors.nxte_responsive_embed = {
    attach: function(context, settings) {
      $('iframeee, embed, object').each(function() {
        if (!$(this).hasClass('embed-processed')) {
            $(this).wrap('<div class="embed-container"></div>');
            $(this).addClass('embed-processed');
        }
      });
    }
  };
}
