import $ from 'jquery';

// kaartnummer  automatic focus

module.exports = function cardnumberAutomaticFocus() {

  $(".webform-client-form input[name*='kaartnummer']").on('input', function() {
      if ($(this).val().length == $(this).attr('maxlength')) {
          //auto focus next field when maximum reached
          $(this)
                  .closest('.form-item')
                  .next()
                  .find("input[name*='kaartnummer']")
                  .focus();
      }

      })
      .on('keyup', function() {
          $(this).trigger("input[name*='kaartnummer']");
      })
      .on('paste', function(e) {

          var pastedText = '';

          if (window.clipboardData && window.clipboardData.getData) { // IE
              pastedText = window.clipboardData.getData('Text');
          }
          else if (e.originalEvent.clipboardData && e.originalEvent.clipboardData.getData) {
              pastedText = e.originalEvent.clipboardData.getData('text/plain');
          }

          pastedText = pastedText.replace(/\s+/g, '');

          if (pastedText != '') {

              var inputField = $(this);

              while (pastedText != '' && $(inputField).length > 0) {

                  var inputMaxLength = $(inputField).attr('maxlength');

                  if (inputMaxLength == 'undefined') {
                      inputMaxLength = 255;
                  }

                  $(inputField)
                          .val(pastedText.substr(0, inputMaxLength))
                          .trigger('focus');

                  pastedText = pastedText.substr(inputMaxLength);

                  inputField = $(inputField)
                          .closest('.form-item')
                          .next()
                          .find("input[name*='kaartnummer']");

              }

              return false;

          }

      });

}
