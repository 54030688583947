import $ from 'jquery';

// Fix navbar

module.exports = function handleFixedNavbar() {

    if (!$('body').hasClass('front') && !$('body').hasClass('page-giftcards')) {
        var paddingTop = $('#navbar').outerHeight();
        $('#page').css('padding-top', paddingTop);
    }

}
