import $ from 'jquery';

// adds a link wraper around rating review on front page
module.exports = function addLinkWrapAroundRatingFrontPage() {

    if ($('body').hasClass('front')) {
        $("#block-nxte-review-nxte-review > .content").wrap("<a href='https://kiyoh.nl/diner_cadeau/' target='_blank' style='color:#000000'></a>")
    }
    
}
