import $ from 'jquery';

// Restaurant pager

module.exports = function drupalBehaviors_restaurantPager() {
  Drupal.behaviors.nxte_restaurant_pager = {
    attach: function(context, settings) {
      if ($(context).hasClass('view-view-1')) {
          //Wrap numbers in footer in  tag <strong> on restaurants page
          $('.page-restaurants-all ul.pager li').css('background', '');
          $('.page-restaurants-all ul.pager').find('.pager-next, .pager-current').prev().css('background', 'none');

          var text = $('.view-footer').text();
          var text_replaced = text.replace(/(\d+)/g, "<strong>$1</strong>");

          $('.view-footer').html(text_replaced);
      }

      //Remove dublicates of text blocks on bestellen page
      if ($('.giftcard-pane-sidebar').length >= 2) {
          $('.giftcard-pane').find('.giftcard-pane-sidebar').remove();
      }
    }
  };
}
