import $ from 'jquery';

//On dinerbon-bestellen page change blocks position on mobile

module.exports = function changeBlocksPositionOnMobile() {
	var topblock = $(".page-giftcards .left");
	var tbottomblock = $('.page-giftcards .giftcard-pane-sidebar');

	$(window).resize(function() {
		moveBlock();
	});

	function moveBlock() {
		if ($(window).width() < 716) {
			topblock.hide();
			$('.page-giftcards .giftcard-pane-sidebar .left').show();
			if (tbottomblock.find($(".left")).length<1) {				
				tbottomblock.prepend(topblock.clone());
			}
		} else {
			topblock.show();
			$('.page-giftcards .giftcard-pane-sidebar .left').hide();
		}
	}

	moveBlock();

}
